var $class="se2--container-filtered-list",$name="ContainerFilteredList",$path="app/components/ContainerFilteredList/index.js",$this={$class,$name,$path,};import lazyHandleOpenModalButtons from 'app/components/utilities/lazyHandleOpenModalButtons';
import shell from 'app/modules/shell';

import isMobileBreakpoint from 'app/utilities/isMobileBreakpoint';
import 'app/partials/image';

import './partials/eventsFiltersGroupList';

import every from 'lodash/every';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import once from 'lodash/once';
import slice from 'lodash/slice';
import some from 'lodash/some';

import {
  cardTagClassName,
  cardsListClassName,
  chipsClassName,
  chipsContainerClassName,
  chosenFiltersMobileClassName,
  clearAllButtonClassName,
  clearAllFilersDesktopClassName,
  closeButtonClassName,
  closeModalViewItemsButtonClassName,
  containerFilterGroupClassName,
  dateClassName,
  filterGroupContainerClassName,
  filterGroupListClassName,
  filterGroupListMobileClassName,
  filteredEventsCountClassName,
  filteredListClassName,
  loadMoreButtonClassName,
  mobileClassName,
  mobileModalTogglerClassName,
  mobileFilteredModalSelector,
  noMatchesImageClassName,
  selectedFiltersCountClassName,
  dataCheckedAttribute,
} from './config';

import { containerFilteredListEventsListClassName } from './partials/eventsList/config';

export default shell.registerComponent($this, ({
  addEventListener, configs, mount,
}) => {
  const { css: { classNames }, defaults } = configs;

  const filterCards = (cards, filters) => {
    const filterKeys = Array.from(filters.keys());
    const isEmptyFilters = every(filterKeys, (key) => filters.get(key).size === 0);

    return isEmptyFilters
      ? cards
      : filter(
        cards,
        (card) => {
          const tagIds = map(
            card.querySelectorAll(`.${cardTagClassName}`),
            ({ dataset }) => dataset.tagId,
          );

          return every(
            filterKeys,
            (groupId) => some(
              tagIds,
              (tagId) => {
                const group = filters.get(groupId);
                return group.has(tagId) || group.size === 0;
              },
            ),
          );
        },
      );
  };

  const revealChips = (chips, filters) => {
    forEach(
      chips,
      ({ classList, dataset }) => {
        classList
          .toggle(
            classNames.hide,
            !filters.get(dataset.groupFilterId)?.has(dataset.tagId),
          );
      },
    );
  };

  const revealCards = (cards, filteredCards, amountOfCards) => {
    forEach(
      cards,
      ({ classList }) => classList.add(classNames.hide),
    );
    forEach(
      slice(filteredCards, 0, amountOfCards),
      ({ classList }) => classList.remove(classNames.hide),
    );
  };

  mount((element) => {
    const filtersGroupMap = new Map();
    const cardElements = Array.from(element.querySelectorAll(`.${containerFilteredListEventsListClassName}`));
    const cardsListElement = element.querySelector(`.${cardsListClassName}`);
    const containerFilterGroup = element.querySelector(`.${containerFilterGroupClassName}`);
    const filterGroupListElement = element.querySelector(`.${filterGroupListClassName}`);
    const loadMoreButtonElement = element.querySelector(`.${loadMoreButtonClassName}`);
    const chipElements = element.querySelectorAll(`.${chipsClassName}`);
    const chipsContainerElement = element.querySelector(`.${chipsContainerClassName}`);
    const noMatchesImageElement = element.querySelector(`.${noMatchesImageClassName}`);
    const clearAllButtonElements = element.querySelectorAll(`.${clearAllButtonClassName}`);
    const mobileModalTogglerElement = element.querySelector(`.${mobileModalTogglerClassName}`);
    const selectedFiltersCountElement = element.querySelector(`.${selectedFiltersCountClassName}`);

    let filteredEventsCountElements = element.querySelectorAll(`.${filteredEventsCountClassName}`);
    let filteredCardsElements = cardElements;
    let maxAmountOfCards = 12;

    const importModal = once(
      async () => (await import('app/partials/modal')).default,
    );

    const getDateFormat = () => cardsListElement.dataset.dateFormat || defaults.defaultDateFormat;

    const updateDateFormat = () => {
      forEach(
        cardsListElement.querySelectorAll(`.${dateClassName}`),
        (dateElement) => {
          const date = new Date(dateElement.innerText);
          dateElement.innerText = getDateFormat()
            .replace('dd', date.getDate())
            .replace('mm', date.getMonth() + 1)
            .replace('yyyy', date.getFullYear());
        },
      );
    };

    const updateLoadMoreButtonElementVisibility = () => {
      loadMoreButtonElement.classList
        .toggle(classNames.hide, filteredCardsElements.length <= maxAmountOfCards);
    };

    const updateAvailableAmountOfCards = async () => {
      const filteredEventsCountElementsModal = (await importModal())
        .getModal()
        .querySelectorAll(`.${filteredEventsCountClassName}`);

      if (filteredEventsCountElementsModal.length) {
        filteredEventsCountElements = [
          ...element.querySelectorAll(`.${filteredEventsCountClassName}`),
          ...filteredEventsCountElementsModal,
        ];
      }

      forEach(
        filteredEventsCountElements,
        // eslint-disable-next-line no-return-assign
        (filteredCardsElement) => filteredCardsElement.innerText = filteredCardsElements.length,
      );

      updateLoadMoreButtonElementVisibility();
    };

    updateDateFormat();
    updateLoadMoreButtonElementVisibility();

    const updateChosenFiltersOnMobile = () => {
      filtersGroupMap.forEach((group, groupId) => {
        const filterGroupListMobileElement = document.body
          ?.querySelector(`${mobileFilteredModalSelector} .${filterGroupListMobileClassName}`);
        const filterGroupElement = filterGroupListMobileElement
          ?.querySelector(`.${filterGroupContainerClassName}[data-filter-group-id="${groupId}"]`);
        const chosenFilterTextElement = filterGroupElement?.querySelector(`.${chosenFiltersMobileClassName}`);
        const filterLabels = map(
          Array.from(group),
          // eslint-disable-next-line no-shadow
          (filter) => element.querySelector(`label[for="${filter}"]`).innerText,
        );
        if (chosenFilterTextElement) chosenFilterTextElement.innerText = filterLabels.join(', ');
      });
    };

    const selectedFiltersCount = () => {
      const amountOfSelectedFilters = Array.from(filtersGroupMap.values())
        .reduce((acc, current) => acc + current.size, 0);
      selectedFiltersCountElement.innerText = amountOfSelectedFilters;
      selectedFiltersCountElement.classList.toggle(classNames.hide, amountOfSelectedFilters === 0);
    };

    const toggleFilter = async (filterGroupId, filterId) => {
      if (!filtersGroupMap.has(filterGroupId)) {
        filtersGroupMap.set(filterGroupId, new Set());
      }

      const clearAllButtonDesktopElements = element.querySelectorAll(
        `.${clearAllFilersDesktopClassName}:not(.${mobileClassName})`,
      );
      const clearAllButtonMobileElement = element.querySelector(`.${clearAllButtonClassName}.${mobileClassName}`);

      const clearAllButtonModal = (await importModal())
        .getModal()
        .querySelector(`.${clearAllButtonClassName}.${mobileClassName}`);

      const filterGroup = filtersGroupMap.get(filterGroupId);

      filterGroup.has(filterId) ? filterGroup.delete(filterId) : filterGroup.add(filterId);

      maxAmountOfCards = 12;

      filteredCardsElements = filterCards(cardElements, filtersGroupMap);
      revealCards(cardElements, filteredCardsElements, maxAmountOfCards);
      updateAvailableAmountOfCards();
      revealChips(chipElements, filtersGroupMap);
      updateChosenFiltersOnMobile();
      selectedFiltersCount();
      noMatchesImageElement.classList
        .toggle(classNames.hide, filteredCardsElements.length !== 0);
      forEach(
        clearAllButtonDesktopElements,
        (button) => button.classList
          .toggle(classNames.hide, filteredCardsElements.length === cardElements.length),
      );

      (isMobileBreakpoint() ? clearAllButtonModal : clearAllButtonMobileElement)?.classList.toggle(
        classNames.disabled,
        filteredCardsElements.length === cardElements.length,

      );
    };

    const onClearAllButtonClick = async () => {
      filtersGroupMap.forEach((group, groupId) => {
        forEach(
          Array.from(group),
          // eslint-disable-next-line no-shadow
          (filter) => toggleFilter(groupId, filter),
        );
      });

      forEach(
        element.querySelectorAll('input[type="checkbox"]'),
        (checkbox) => {
          checkbox.removeAttribute('checked');
          checkbox.checked = false;
        },
      );

      forEach(
        (await importModal())
          .getModal()
          .querySelectorAll('input[type="checkbox"]'),
        (checkbox) => {
          checkbox.removeAttribute(dataCheckedAttribute);
          checkbox.checked = false;
        },
      );
    };

    const onFilterClick = ({ target }) => {
      if (target.tagName !== 'LABEL') return;

      const filterId = target.getAttribute('for');
      const { filterGroupId } = target.closest(`.${filterGroupContainerClassName}`).dataset;
      toggleFilter(filterGroupId, filterId);

      const filterDesktop = document.getElementById(filterId);
      const filterMobile = document.getElementById(`mobile-${filterId}`);

      filterDesktop.checked
        ? filterMobile.removeAttribute('checked')
        : filterMobile.setAttribute('checked', true);
    };

    const onMobileFilterClick = ({ target }) => {
      if (target.tagName !== 'LABEL') return;

      const filterGroupElement = target.closest(`.${filterGroupContainerClassName}`);
      const filterId = target.getAttribute('for').replace('mobile-', '');
      const { filterGroupId } = filterGroupElement.dataset;
      toggleFilter(filterGroupId, filterId);

      const filterMobile = document.getElementById(`mobile-${filterId}`);
      const filterDesktop = document.getElementById(filterId);
      if (filterMobile.dataset.checked === 'true') {
        filterMobile.removeAttribute(dataCheckedAttribute);
        filterDesktop.checked = false;
      } else {
        filterMobile.dataset.checked = true;
        filterDesktop.checked = true;
      }
    };

    const onChipClick = ({ target }) => {
      const chip = target.closest(`.${chipsClassName}`);
      if (!chip) return;
      const { tagId, groupFilterId } = chip.dataset;
      toggleFilter(groupFilterId, tagId);
      document.getElementById(tagId).checked = false;
      const filterMobile = document.getElementById(`mobile-${tagId}`);
      filterMobile.checked = false;
      filterMobile.removeAttribute('checked');
    };

    const loadMoreButtonClick = () => {
      maxAmountOfCards += 12;
      revealCards(cardElements, filteredCardsElements, maxAmountOfCards);
      updateAvailableAmountOfCards();
    };

    const closeModal = async () => {
      const modal = await importModal();
      if (modal.wrapElement.classList.contains(filteredListClassName)) {
        containerFilterGroup.innerHTML = modal.placeholderElement.cloneNode(true).innerHTML;
        forEach(
          containerFilterGroup.querySelectorAll("input[data-checked='true']"),
          (input) => {
            input.removeAttribute(dataCheckedAttribute);
            input.setAttribute('checked', true);
          },
        );
        modal.hideModal();
      }
    };

    const openFilterListOnMobile = async () => {
      const modalElement = (await importModal()).showModal({
        isFilteredList: true,
        appendHTML: `${containerFilterGroup.cloneNode(true).innerHTML}`,
      });

      forEach(
        modalElement.querySelectorAll("input[checked='true']"),
        // TODO: Investigate how this logic below can be improved
        (input) => {
          input.removeAttribute('checked');
          input.checked = true;
          input.dataset.checked = true;
        },
      );

      addEventListener(
        modalElement.querySelector(`.${filterGroupListMobileClassName}`),
        'click',
        onMobileFilterClick,
      );

      addEventListener(
        modalElement.querySelector(`.${closeButtonClassName}`),
        'click',
        closeModal,
      );

      addEventListener(
        modalElement.querySelector(`.${closeModalViewItemsButtonClassName}`),
        'click',
        closeModal,
      );

      addEventListener(
        modalElement.querySelector((`.${clearAllButtonClassName}.${mobileClassName}`)),
        'click',
        onClearAllButtonClick,
      );

      containerFilterGroup.innerHTML = '';

      updateChosenFiltersOnMobile();
    };

    lazyHandleOpenModalButtons(addEventListener, element);

    addEventListener(window, 'resize', async () => {
      const modalElementIsVisible = !(await importModal()).getModal().classList.contains(configs.css.classNames.hide);
      if (!isMobileBreakpoint() && modalElementIsVisible) closeModal();
    });

    addEventListener(filterGroupListElement, 'click', onFilterClick);
    addEventListener(loadMoreButtonElement, 'click', loadMoreButtonClick);
    addEventListener(chipsContainerElement, 'click', onChipClick);
    addEventListener(mobileModalTogglerElement, 'click', () => openFilterListOnMobile());
    forEach(
      clearAllButtonElements,
      (clearButtonElement) => addEventListener(clearButtonElement, 'click', onClearAllButtonClick),
    );
    revealCards(cardElements, filteredCardsElements, maxAmountOfCards);
  });
});
