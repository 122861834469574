var $class="se2--container-filtered-list",$name="ContainerFilteredList",$path="app/components/ContainerFilteredList/config.js",$this={$class,$name,$path,};export const cardTagClassName = 'card-tag';
export const cardsListClassName = 'cards-list';
export const chipsClassName = 'filter-chip';
export const chipsContainerClassName = 'filter-chips-list';
export const chosenFiltersMobileClassName = 'chosen-filters-text';
export const clearAllButtonClassName = 'clear-all-filters';
export const clearAllFilersDesktopClassName = 'clear-all--desktop';
export const closeButtonClassName = 'close-btn';
export const closeModalViewItemsButtonClassName = 'modal-close-filters';
export const containerFilterGroupClassName = 'container-filter-group';
export const dateClassName = 'date';
export const filterGroupContainerClassName = 'events-filter-group';
export const filterGroupListClassName = 'events-filter-group-list';
export const filterGroupListMobileClassName = 'events-filter-group-list-mobile';
export const filteredEventsCountClassName = 'filtered-events-count';
export const filteredListClassName = 'filtered-list';
export const loadMoreButtonClassName = 'load-more-button-wrap';
export const mobileClassName = 'mobile';
export const mobileModalTogglerClassName = 'events-filter-modal-toggle';
export const noMatchesImageClassName = 'no-matches-found';
export const selectedFiltersCountClassName = 'selected-filters-count';
export const mobileFilteredModalSelector = '.modal-wrap.filtered-list';
export const dataCheckedAttribute = 'data-checked';
