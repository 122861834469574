var $class="se2--container-filtered-list--events-filters-group-list",$name="ContainerFilteredList/eventsFiltersGroupList",$path="app/components/ContainerFilteredList/partials/eventsFiltersGroupList/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';

import 'app/partials/checkbox';

import { css } from 'configs';
import { filterToggleBlockClassName } from './config';

export default shell.registerPartial($this, ({ mount, addEventListener }) => {
  mount((element) => {
    addEventListener(element, 'click', ({ target }) => {
      target.closest(`.${filterToggleBlockClassName}`)?.classList.toggle(css.classNames.close);
    });
  });
});
